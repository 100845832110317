<template>
  <div>
    <div v-if="selectedItems && selectedItems.length" :name="$t('filters.selected.label')">
      <div>
        <pdl-section-header size="sm" :is-non-stacking="true" class="mb-2 show-for-large">
          <template slot="content">
            <pdl-heading>{{ $t('filters.selected.label') }}</pdl-heading>
          </template>
          <template slot="actions">
            <div
              v-show="selectedItems.length > 0"
              class="cursor-pointer font-normal text-sm my-0"
              @click="clearFilters"
            >
              {{ $t('search.nav.clearSelections') }}
            </div>
          </template>
        </pdl-section-header>
      </div>

      <ul class="facet-group__list">
        <li
          v-for="item in selectedItems"
          :key="item.id"
          class="facet-group__list-item mb-25"
          :class="{'is-active': activeFilters.indexOf(item.id) > -1}"
          :name="item.id"
        >
          <form class="flex justify-between label-selected" action="refine/" method="get">
            <label
              v-if="item.type === 'color'"
              class="cursor-pointer flex grow md:mb-1 items-center"
              :for="item.id"
              @click="handleItemClick(item)"
              ><span class="sr-only">color: ${{ item.text }}</span>
              <pdl-swatch
                :name="item.id"
                :first-color="getColorFromCode(item.id, 0)"
                :second-color="getColorFromCode(item.id, 1)"
              />
              <em>{{ item.text }}</em>
            </label>

            <label v-else class="cursor-pointer grow pl-1" :for="item.id" @click="handleItemClick(item)">
              <em>{{ item.text }}</em>
            </label>

            <div class="facet-group__row-right flex items-center">
              <input :id="item.id" class="facet-group__check" type="checkbox" />
              <button v-show="item.active" class="facet-group__decorator-refinement" @click="handleItemClick(item)">
                <pdl-icon name="cancel" size="18" :label="$t('text.cancel')" />
              </button>
            </div>
          </form>
        </li>
      </ul>

      <trek-button secondary block type="button" class="hide-for-large mt-2" @click="clearFilters">
        {{ $t('search.nav.clearSelections') }}
      </trek-button>
    </div>

    <pdl-collapse-item :name="groupName">
      <template slot="title">
        <pdl-heading size="sm">{{ groupName }}</pdl-heading>
      </template>

      <ul class="facet-group__list">
        <li
          v-for="item in items"
          :key="item.id"
          class="facet-group__list-item mb-25"
          :class="{'is-active': activeFilters.indexOf(item.id) > -1}"
          :name="item.id"
        >
          <form class="flex justify-between label-selected" action="refine/" method="get">
            <label
              v-if="item.type === 'color'"
              class="cursor-pointer flex grow md:mb-1 items-center"
              :for="item.id"
              @click="handleItemClick(item)"
              ><span class="sr-only">color: ${{ item.text }}</span>
              <pdl-swatch
                :name="item.id"
                :first-color="getColorFromCode(item.id, 0)"
                :second-color="getColorFromCode(item.id, 1)"
              />
              <em>{{ item.text }}</em>
            </label>

            <label v-else class="cursor-pointer grow pl-1" :for="item.id" @click="handleItemClick(item)">
              <em>{{ item.text }}</em>
            </label>

            <div class="facet-group__row-right flex items-center">
              <input :id="item.id" class="facet-group__check" type="checkbox" />
              <span v-show="item.active" class="facet-group__decorator-refinement" @click="handleItemClick(item)">
                <pdl-icon name="done" size="18" :label="$t('form.checkout.select')" />
              </span>
            </div>
          </form>
        </li>
      </ul>
    </pdl-collapse-item>
  </div>
</template>

<script>
import {PdlCollapseItem} from '@pedal/pdl-collapse';
import Emitter from '../mixins/emitter';
import filter from 'lodash/filter';
import find from 'lodash/find';
import {PdlSwatch} from '@pedal/pdl-swatch';
import swatchMixin from '@/mixins/swatches.js';
import TrekButton from '@/components/TrekButton';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'FilterGroup',
  componentName: 'FilterGroup',
  components: {PdlSwatch, PdlCollapseItem, TrekButton, PdlSectionHeader, PdlHeading, PdlIcon},
  mixins: [Emitter, swatchMixin],

  props: {
    groupName: {
      type: String,
      default: null,
    },
    value: {
      type: [Array, String, Number],
      default() {
        return [];
      },
    },
    items: {
      type: [Array],
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      activeFilters: [].concat(this.value),
      selectedItems: [],
    };
  },

  watch: {
    value(value) {
      this.activeNames = [].concat(value);
    },

    activeFilters() {
      this.updateSelectedItems();
    },
  },

  methods: {
    updateSelectedItems() {
      this.selectedItems = filter(this.items, (item) => {
        const found = find(this.activeFilters, (filter) => filter === item.id);
        return found != null;
      });
    },

    clearFilters() {
      this.activeFilters = [];
      this.items.map((item) => (item.active = false));
      this.setActiveFilters(this.activeFilters);
    },

    setActiveFilters(activeFilters) {
      activeFilters = [].concat(activeFilters);
      let value = this.accordion ? activeFilters[0] : activeFilters;
      this.activeFilters = activeFilters;
      this.$emit('input', value);
      this.$emit('change', value);
    },

    handleItemClick(item) {
      let activeFilters = this.activeFilters.slice(0);
      let index = activeFilters.indexOf(item.id);
      if (index > -1) {
        activeFilters.splice(index, 1);
      } else {
        activeFilters.push(item.id);
      }

      this.setActiveFilters(activeFilters);
      this.dispatch('FilterGroups', 'filter-changed', item);
    },
  },
};
</script>

<style scoped>
.facet-group__list-item label {
  display: flex;
  align-items: center;
}

.facet-group__decorator-refinement {
  color: black;
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 16' version='1.1' fill='#999999' width='12' height='16' aria-hidden='true'%3E%3Cpath fill-rule='evenodd' d='M12 5l-8 8-4-4 1.5-1.5L4 10l6.5-6.5L12 5z'%3E%3C/path%3E%3C/svg%3E");
  top: 50%;
  transform: translateY(-50%);
}

.facet-group__decorator-refinement::before,
.facet-group__decorator-refinement::after {
  display: none;
}

.facet-group__row-right i {
  padding-top: 0;
}
</style>
