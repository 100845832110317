<template>
  <section>
    <!-- Part Type Radio (Bikes/Accesories) -->
    <form-group
      v-model="localClaimLine.productType"
      class="my-2"
      type="radio"
      qaid="warranty-claim-line-issue-when"
      :multi-items="partTypeOptions"
      :name="inputName('part-type')"
      :error="getErrorMessage('localClaimLine.productType')"
      required
      show-error-messages
      force-display-error
      @change="handlePartTypeChange"
    >
      <template #label>
        <legend class="form-fieldset__legend text-lg font-bold" qaid="warranty-claim-line-details-type-label">
          {{ $t('text.productType') }}
          <span class="text-red ml-25">
            *
            <span class="show-for-sr">(required)</span>
          </span>
        </legend>
      </template>

      <template #form-errors>
        <span class="form-feedback">{{ $t('warrantyClaims.B2B.allFieldsRequired') }}</span>
      </template>
    </form-group>

    <hr class="rule my-4" />

    <pdl-section-header size="sm" class="mb-3">
      <template slot="content">
        <pdl-heading :level="4" qaid="warranty-claim-line-details-sub-header">
          {{ $t('text.productDetails') }}
        </pdl-heading>
      </template>
    </pdl-section-header>

    <!-- Serial Number -->
    <div v-show="claimLine.productType" class="grid-x grid-margin-x">
      <div class="cell large-6">
        <div v-if="claimLine.productType === 'Bikes'">
          <div
            class="form-group"
            :class="{'has-error': !!getErrorMessage('localClaimLine.serialNumber') || showSerialWarning}"
          >
            <p class="mb-1">{{ $t('warrantyClaims.B2B.serialNumber.enterGetStarted') }}</p>
            <label for="serial-number" class="form-label" qaid="warranty-claim-line-details-serial-label">
              <span>{{ $t('warrantyClaims.B2B.serialNumber') }}</span>
              <span class="text-red ml-25">*</span>
            </label>
            <div class="form-group--inline is-required mr-0">
              <input
                v-show="claimLine.productType === 'Bikes'"
                id="serial-number"
                :key="inputName('serialNumber')"
                v-model="localClaimLine.serialNumber"
                type="text"
                class="form-control grow"
                qaid="warranty-claim-line-details-serial"
                data-name="serialNumber"
                :name="inputName('serialNumber')"
                :maxlength="30"
                :placeholder="$t('warrantyClaims.B2B.serialNumber.enter')"
                @change="autoSave"
                @blur="v$.localClaimLine.serialNumber.$touch()"
              />
              <trek-button primary form-inline class="md:ml-1" @click="processSerial">
                <span>{{ $t('productCompare.addProduct') }}</span>
              </trek-button>
            </div>
            <div v-show="getErrorMessage('localClaimLine.serialNumber')" class="form-feedback is-invalid">
              {{ getErrorMessage('localClaimLine.serialNumber') }}
            </div>
          </div>
          <!-- Serial Number Image -->
          <div
            class="form-group lg:w-3/4"
            :class="{'has-error': !!getErrorMessage('localClaimLine.serialNumberImage.name')}"
          >
            <label class="form-label" qaid="warranty-claim-line-details-serial-image-label">
              <span>{{ $t('warrantyClaims.B2B.serialNumber.uploadImage') }}</span>
              <span class="text-red ml-25">*</span>
            </label>
            <pdl-file-upload
              :click-to-upload-msg="$t('text.uploadImage')"
              :upload-button="false"
              :input-id="`${claimIndex}_serialNumberImage`"
              :in-file-name="serialImage.name"
              :help="null"
              :in-preset-image-file-url="serialImage.inPresetImageFileUrl"
              qaid="warranty-claim-line-details-serial-image"
              :upload-on-input="true"
              :has-error="!!getErrorMessage('localClaimLine.serialNumberImage.name')"
              :file-too-large-msg="$t('myCompany.imageUpload.fileTooLarge')"
              :wrong-file-type-msg="$t('orderUpload.B2B.fileTypeError')"
              :is-filename-visible="serialImage.showFileName"
              @file-uploaded="setImage({property: 'serialNumberImage'}, $event)"
              @clear-file="clearImage('serialNumberImage')"
            />
            <div v-show="getErrorMessage('localClaimLine.serialNumberImage.name')" class="form-feedback is-invalid">
              {{ getErrorMessage('localClaimLine.serialNumberImage.name') }}
            </div>
          </div>
        </div>
        <!-- Validate SKU Number -->
        <div v-if="claimLine.productType === 'PartsAndAccessories'">
          <div class="form-group" :class="{'has-error': !!getErrorMessage('localClaimLine.sku')}">
            <p class="mb-1">{{ $t('warrantyClaims.B2B.getStarted') }}</p>
            <label for="sku" class="form-label" qaid="warranty-claim-line-details-sku-label">
              {{ $t('warrantyClaims.B2B.enterSKU') }}
              <span class="text-red ml-25">
                *
                <span class="show-for-sr">{{ $t('form.field.required') }}</span>
              </span>
            </label>
            <div class="form-group--inline is-required mr-0">
              <input
                v-model="localClaimLine.sku"
                type="text"
                :maxlength="25"
                class="form-control grow"
                qaid="warranty-claim-line-details-sku"
                :name="inputName('sku')"
                @blur="v$.localClaimLine.sku.$touch()"
                @change="autoSave"
              />
              <trek-button primary form-inline class="md:ml-1" @click="processSku">
                <span>{{ $t('productCompare.addProduct') }}</span>
              </trek-button>
            </div>
            <div
              v-show="!!getErrorMessage('localClaimLine.sku')"
              class="form-feedback is-invalid"
              v-text="getErrorMessage('localClaimLine.sku')"
            />

            <div v-if="showSkuNotFound" class="my-2 text-warning">{{ $t('warrantyClaims.B2B.confirmSku') }}</div>
          </div>
        </div>
      </div>

      <div class="cell large-6">
        <div :key="inputName('form')" class="grid md:grid-cols-4 gap-x-4 relative">
          <div
            v-if="!enableProductFields"
            class="warranty-claim-product-overlay"
            :class="{'warranty-claim-product-overlay--loading': confirmLoading}"
          >
            <p v-if="!confirmLoading" class="warranty-claim-product-overlay__label">
              <pdl-icon class="shrink-0" name="note_add" />
              <span class="inline-block ml-1">{{
                claimLine.productType === 'PartsAndAccessories'
                  ? $t('warrantyClaims.B2B.productAppear')
                  : $t('warrantyClaims.B2B.serialNumber.enterGetStarted.appear')
              }}</span>
            </p>
            <pdl-loading v-else :message="$t('text.loadingMessage')" :is-loading="confirmLoading" />
          </div>
          <div class="md:col-span-3">
            <form-group
              :key="inputName('model')"
              v-model="localClaimLine.modelName"
              type="text"
              :label="$t('garage.bike.type')"
              qaid="warranty-claim-line-details-model"
              :name="inputName('model')"
              :error="getErrorMessage('localClaimLine.modelName')"
              required
              show-error-messages
              force-display-error
              @blur="v$.localClaimLine.modelName.$touch()"
              @change="autoSave"
            />
          </div>
          <div class="md:col-span-1">
            <form-group
              :key="inputName('qty')"
              v-model="localClaimLine.qty"
              type="number"
              qaid="warranty-claim-line-details-qty"
              :name="inputName('qty')"
              :label="$t('text.quantity')"
              :allowed-characters-regex="`^[0-9]*$`"
              :error="getErrorMessage('localClaimLine.qty')"
              required
              show-error-messages
              force-display-error
              @blur="v$.localClaimLine.qty.$touch()"
              @change="autoSave"
            />
          </div>
          <div v-if="claimLine.productType === 'Bikes'" class="md:col-span-2">
            <form-group
              :key="inputName('sku')"
              v-model="localClaimLine.sku"
              type="text"
              qaid="warranty-claim-line-details-sku"
              :name="inputName('sku')"
              :label="$t('product.variants.sku')"
              :maxlength="25"
              :error="getErrorMessage('localClaimLine.sku')"
              required
              show-error-messages
              force-display-error
              @blur="v$.localClaimLine.sku.$touch()"
              @change="autoSave"
            />
          </div>
          <div v-if="claimLine.productType === 'Bikes' && enableProductFields" class="md:col-span-2">
            <form-group
              :key="inputName('modelYear')"
              v-model="localClaimLine.modelYear"
              type="select"
              qaid="warranty-claim-line-details-year"
              :name="inputName('modelYear')"
              :multi-items="modelYearList"
              :label="$t('contactUs.year')"
              :error="getErrorMessage('localClaimLine.modelYear')"
              required
              show-error-messages
              force-display-error
              @blur="v$.localClaimLine.sku.$touch()"
              @change="handleModelYearChange"
            />
          </div>
          <div class="md:col-span-2">
            <form-group
              :key="inputName('color')"
              v-model="localClaimLine.color"
              type="text"
              qaid="warranty-claim-line-details-color"
              :name="inputName('color')"
              :label="$t('product.variants.colour')"
              :error="getErrorMessage('localClaimLine.color')"
              :required="claimLine.productType === 'Bikes'"
              show-error-messages
              force-display-error
              @blur="v$.localClaimLine.color.$touch()"
              @change="autoSave"
            />
          </div>
          <div class="md:col-span-2">
            <form-group
              :key="inputName('productSize')"
              v-model="localClaimLine.size"
              type="text"
              qaid="warranty-claim-line-details-size"
              :name="inputName('size')"
              :label="$t('text.productSize')"
              :required="claimLine.productType === 'Bikes'"
              :error="showSizeWarning ? sizeWarning : getErrorMessage('localClaimLine.size')"
              show-error-messages
              force-display-error
              @blur="v$.localClaimLine.size.$touch()"
              @change="autoSave"
            />
          </div>
          <div class="md:col-span-2">
            <div
              id="purchase-date-field"
              class="form-group"
              :class="{'has-error': !!getErrorMessage('localClaimLine.purchasedDate')}"
            >
              <label class="font-medium flex" qaid="warranty-claim-line-details-purchase-label">
                <span>{{ $t('contactUS.purchaseDate') }}</span>
                <span class="text-red ml-25">
                  *
                  <span class="show-for-sr">{{ $t('form.field.required') }}</span>
                </span>
              </label>
              <pdl-date-picker
                id="purchase-date-input"
                ref="purchaseDatePicker"
                qaid="warranty-claim-line-details-purchase"
                :allow-input="true"
                :name="inputName('purchaseDate')"
                :error="getErrorMessage('localClaimLine.purchasedDate')"
                :value="localClaimLine.purchasedDate"
                min-date="1976-01-01"
                required
                @change="updatePurchaseDate"
              />
              <div
                v-show="!!getErrorMessage('localClaimLine.purchasedDate')"
                class="form-feedback is-invalid"
                v-html="getErrorMessage('localClaimLine.purchasedDate')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import {mapActions, mapMutations, mapGetters} from 'vuex';
import TrekButton from '@/components/TrekButton';
import FormGroup from '@/components/FormGroup';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlDatePicker} from '@pedal/pdl-date-picker';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlFileUpload} from '@pedal/pdl-file-upload';
import {PdlIcon} from '@pedal/pdl-icon';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
import {useVuelidate} from '@vuelidate/core';

export default {
  components: {
    TrekButton,
    PdlDatePicker,
    FormGroup,
    PdlLoading,
    PdlSectionHeader,
    PdlHeading,
    PdlFileUpload,
    PdlIcon,
  },
  mixins: [TrekValidationMixin],

  props: {
    claimLine: {
      type: Object,
      required: true,
    },
    claimIndex: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      confirmLoading: false,
      showSerialNotFound: false,
      showSkuNotFound: false,
      serialValidated: false,
      loadedProductDataForSku: '',
      localClaimLine: this.claimLine,
    };
  },

  validations() {
    return {
      localClaimLine: {
        productType: {
          required: this.trekValidators.required(this.inputName('part-type')),
        },
        // if bikes
        serialNumber: {
          requiredIf: this.trekValidators.requiredIf(
            'warrantyClaims.B2B.serialNumber',
            this.localClaimLine.productType === 'Bikes'
          ),
        },
        serialNumberImage: {
          name: {
            requiredIf: this.trekValidators.requiredIf(
              'warrantyClaims.B2B.serialNumber.uploadImage',
              this.localClaimLine.productType === 'Bikes'
            ),
          },
        },
        modelYear: {
          requiredIf: this.trekValidators.requiredIf('contactUs.year', this.localClaimLine.productType === 'Bikes'),
        },
        color: {
          requiredIf: this.trekValidators.requiredIf(
            'product.variants.colour',
            this.localClaimLine.productType === 'Bikes'
          ),
        },
        size: {
          requiredIf: this.trekValidators.requiredIf('text.productSize', this.localClaimLine.productType === 'Bikes'),
        },
        // if enableProductFields
        purchasedDate: {
          requiredIf: this.trekValidators.requiredIf('contactUS.purchaseDate', !!this.enableProductFields),
        },
        modelName: {
          requiredIf: this.trekValidators.requiredIf('garage.bike.type', !!this.enableProductFields),
        },
        qty: {
          requiredIf: this.trekValidators.requiredIf('text.quantity', !!this.enableProductFields),
        },

        // if either enableProductFields or if PartsAndAccessories
        sku: {
          requiredIf: this.trekValidators.requiredIf(
            'product.variants.sku',
            this.localClaimLine.productType === 'PartsAndAccessories' || !!this.enableProductFields
          ),
        },
      },
    };
  },

  computed: {
    ...mapGetters('warrantyClaims', ['isActionRequiredStatus']),

    enableProductFields() {
      if (this.confirmLoading) {
        return false;
      }

      if (this.localClaimLine.productType === 'PartsAndAccessories') {
        return this.loadedProductDataForSku && this.loadedProductDataForSku === this.localClaimLine.sku;
      }

      return (this.serialValidated && !this.showSerialNotFound) || this.hasSerialImage;
    },

    hasSerialImage() {
      return this.claimLine.serialNumberImage?.url?.length;
    },

    showSerialWarning() {
      return includes(this.localClaimLine.waitingCodeList, 'S');
    },

    showSizeWarning() {
      return includes(this.localClaimLine.waitingCodeList, 'F');
    },

    sizeWarning() {
      return this.$t('warrantyClaims.B2B.warning.sizeRequired').substring(
        this.$t('warrantyClaims.B2B.warning.sizeRequired').indexOf('-') + 2,
        this.$t('warrantyClaims.B2B.warning.sizeRequired').length
      );
    },

    partTypeOptions() {
      return [
        {
          value: 'Bikes',
          displayName: this.$t('text.bikes'),
        },
        {
          value: 'PartsAndAccessories',
          displayName: this.$t('text.partsAccessories'),
        },
      ];
    },
    modelYearList() {
      return this.claimLine.modelYearList?.map((year) => ({value: year, displayName: year})) || [];
    },

    serialImage() {
      const file = this.claimLine.serialNumberImage;
      return {
        ...file,
        inPresetImageFileUrl: this.hasSerialImage ? file.url || file.fileEncoded : null,
        showFileName: !!(file && file.name && (!this.isActionRequiredStatus || file.url)),
      };
    },
  },

  watch: {
    claimLine: {
      deep: true,
      handler(value) {
        this.localClaimLine = value;
        if (this.localClaimLine && this.localClaimLine.qty === 0) this.localClaimLine.qty = 1;
        this.v$.localClaimLine.qty.$touch();
      },
    },
    localClaimLine: {
      handler(value) {
        if (this.localClaimLine?.qty === 0) this.localClaimLine.qty = null;
        this.updateClaimLine({index: this.claimIndex, value});
      },
      deep: true,
    },
  },

  mounted() {
    // Assuming that if the claim line has a serial already then it's verified (for validation purposes)
    if (
      this.localClaimLine.productType === 'Bikes' &&
      this.localClaimLine.serialNumber &&
      this.localClaimLine.serialNumber.length
    ) {
      this.serialValidated = true;
    } else if (
      this.localClaimLine.productType === 'PartsAndAccessories' &&
      this.localClaimLine.sku &&
      this.localClaimLine.sku.length
    ) {
      this.serialValidated = true;
      this.loadedProductDataForSku = this.localClaimLine.sku;
    }
  },

  methods: {
    ...mapActions('warrantyClaims', ['updateProperty', 'saveClaim', 'saveImageInput', 'updateClaimLineProperty']),
    ...mapMutations('warrantyClaims', ['updateClaimLine']),

    inputName(propertyName) {
      return `cl${this.claimIndex}_${propertyName}`;
    },

    processSerial() {
      this.clearWarningMessages();

      // Clear old fields
      this.localClaimLine.sku = '';
      this.localClaimLine.modelName = '';
      this.localClaimLine.modelYear = '';
      this.localClaimLine.size = '';
      this.localClaimLine.color = '';

      this.serialValidated = false;
      this.localClaimLine.warrantyRequiredImagesList = [];

      this.localClaimLine.issueTypeNameSelected = '';
      this.$forceUpdate();

      if (this.localClaimLine && this.localClaimLine.serialNumber) {
        this.confirmLoading = true;

        storefrontInstance
          .get(`/claims/serial/${this.localClaimLine.serialNumber}/`)
          .then((response) => {
            if (!isEmpty(response.data.data)) {
              this.localClaimLine.sku = response.data.data.sku;
              this.localClaimLine.modelName = response.data.data.modelName;
              this.localClaimLine.modelYear = response.data.data.modelYear;
              this.localClaimLine.size = response.data.data.size;
              this.localClaimLine.color = response.data.data.color;

              this.$emit('issue-types-update', response.data.data.warrantyIssues);

              this.serialValidated = true;

              // Force update since we're modifying deeper attributes than what Vue is watching for
              this.$forceUpdate();
            }

            if (!isEmpty(response.data.meta.feedback.message)) {
              if (response.data.meta.feedback.message.basePropertyKey === 'warrantyClaims.B2B.unableLocateSerial') {
                this.showSerialNotFound = true;
                this.serialValidated = false;
              }
            }
          })
          .finally(() => {
            this.confirmLoading = false;
            this.saveClaim();
          });
      }
    },

    processSku() {
      this.clearWarningMessages();

      // Clear old fields
      this.localClaimLine.modelName = '';
      this.localClaimLine.modelYear = '';
      this.localClaimLine.size = '';
      this.localClaimLine.color = '';

      this.serialValidated = false;
      this.localClaimLine.warrantyRequiredImagesList = [];
      this.loadedProductDataForSku = '';

      this.localClaimLine.issueTypeNameSelected = '';
      this.$forceUpdate();

      if (this.localClaimLine && this.localClaimLine.sku) {
        this.confirmLoading = true;

        storefrontInstance
          .get(`/claims/sku/${this.localClaimLine.sku}/`)
          .then((response) => {
            if (!isEmpty(response.data.data)) {
              this.localClaimLine.modelName = response.data.data.modelName;
              this.localClaimLine.modelYear = response.data.data.modelYear;
              this.localClaimLine.size = response.data.data.size;
              this.localClaimLine.color = response.data.data.color;
              this.loadedProductDataForSku = this.localClaimLine.sku;

              this.$emit('issue-types-update', response.data.data.warrantyIssues);

              // Force update since we're modifying deeper attributes than what Vue is watching for
              this.$forceUpdate();
            }

            if (!isEmpty(response.data.meta.feedback.message)) {
              if (response.data.meta.feedback.message.basePropertyKey === 'warrantyClaims.B2B.unableLocateSku') {
                this.showSkuNotFound = true;
              }
            }
          })
          .finally(() => {
            this.confirmLoading = false;
            this.saveClaim();
          });
      }
    },

    setImage({property, index = null}, {name, encodedFile}) {
      const image = {encodedFile, name, url: null};

      if (property === 'warrantyOptionalAttachments') {
        this.localClaimLine[property][index] = image;
      } else {
        this.localClaimLine[property] = image;
      }

      this.$nextTick(() => this.v$.localClaimLine.serialNumberImage.name.$touch());

      if (!this.isActionRequiredStatus) {
        this.saveImageInput();
      }
    },

    autoSave() {
      this.saveClaim();
    },
    updateVuex(e) {
      this.updateProperty({
        propName: e.target.getAttribute('name'),
        value: e.target.value,
      });
    },

    clearWarningMessages() {
      this.showSerialNotFound = false;
      this.showSkuNotFound = false;
    },

    updatePurchaseDate({event}) {
      const dateCheck = new Date(1976, 0, 1);
      const selectedDate = new Date(event);
      if (this.localClaimLine.purchasedDate != event && selectedDate >= dateCheck) {
        this.localClaimLine.purchasedDate = event;
        this.autoSave();
      }
    },

    clearImage(property) {
      const clearImg = {
        encodedImage: null,
        name: null,
        url: null,
      };
      this.$set(this.localClaimLine, property, clearImg);

      this.$nextTick(() => this.v$.localClaimLine.serialNumberImage.name.$touch());
    },

    handleModelYearChange(e) {
      this.clearWarningMessages();
      this.updateClaimLineProperty({claimLineId: this.claimLine.id, propName: 'modelYear', value: e.target.value});
    },
    handlePartTypeChange(e) {
      this.clearWarningMessages();
      this.localClaimLine.issueTypeNameSelected = '';
      this.updateClaimLineProperty({claimLineId: this.claimLine.id, propName: 'productType', value: e.target.value});
      this.v$.localClaimLine.productType.$touch();
    },

    checkQtyInput() {
      if (!this.claimLine?.qty) this.localClaimLine.qty = 1;
    },
  },
};
</script>
<style scoped>
.warranty-claim-product-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gray-10);
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 25%;
  color: var(--gray-70);
}

.warranty-claim-product-overlay__label {
  display: flex;
}

.warranty-claim-product-overlay--loading {
  color: var(--gray-100);
  justify-content: center;
}
</style>
